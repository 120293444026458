<template>
  <div
    v-if="lesson"
    class="page-lesson"
    :data-class-id="lesson.class"
  >
    <div
      class="page-header"
      :data-class-id="lesson.class"
    >
      <router-link :to="{ name: 'ccmp-lesson', params: { id: lesson.id }}" class="page-header__link-back">
        <i class="material-icons">keyboard_arrow_left</i>
      </router-link>
      <div class="page-header__info">
        <span class="page-header__breadcrumbs">
          Class {{ lesson.class }}
          <i class="material-icons">arrow_right</i>
          Objective {{ formatObjectiveId(objective.id) }}
        </span>
        <span class="page-header__title">{{ objective.name }}</span>
      </div>
    </div>

    <div class="table-container">
      <table class="table-objective-details">
        <thead>
          <tr>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <vue-markdown-it class="markdown-container" :source="objective.purpose" />
            </td>
          </tr>
        </tbody>
      </table>

      <table v-if="isVisible(objective.inputs)" class="table-objective-details">
        <thead>
          <tr>
            <th>Inputs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="input in objective.inputs" :key="input.id">
            <td>{{ input.name }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table v-if="isVisible(objective.outputs)" class="table-objective-details">
        <thead>
          <tr>
            <th>Outputs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="output in objective.outputs" :key="output.id">
            <td>{{ output.name }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
import VueMarkdownIt from 'vue3-markdown-it'

export default {
  name: 'CcmpObjective',

  props: ['id'],

  components: {
    VueMarkdownIt
  },

  computed: {
    lesson () {
      // Find in process steps
      let lesson = this.$store.state.ccmpData.lessons.find((lesson) => {
        return lesson.id === this.objective.processGroup
      })

      if (!lesson) {
        const processStep = this.$store.state.ccmpData.processSteps.find((processStep) => {
          return processStep.id === this.objective.processStepId[0]
        })

        lesson = this.$store.state.ccmpData.lessons.find((lesson) => {
          return lesson.id === processStep.processGroup
        })
      }

      return lesson
    },

    objective () {
      // Find in process steps
      let objective = this.$store.state.ccmpData.processSteps.find((processStep) => {
        return processStep.id === this.id
      })

      // Find in process sub steps
      if (!objective) {
        objective = this.$store.state.ccmpData.processSubSteps.find((processSubStep) => {
          return processSubStep.id === this.id
        })
      }

      return objective
    },

    isCcmpPro () {
      return this.$store.state.account.ccmpPro
    }
  },

  methods: {
    formatObjectiveId (id) {
      return id
    },

    formatRichText (text) {
      return text.replace(/\n/g, '<br />')
    },

    isVisible (items) {
      if (items && items.length === 1 && items[0].name === 'None') {
        return false
      }

      return true
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lesson {
    height: 100%;
    display: flex;
    flex-direction: column;

    &[data-class-id="1"] {
      background: $orange-light;

      .table-objective-details th {
        border-bottom: 1px solid $orange-mid;
      }
    }

    &[data-class-id="2"] {
      background: $jtask-blue-light;

      .table-objective-details th {
        border-bottom: 1px solid $jtask-blue-mid;
      }
    }

    &[data-class-id="3"] {
      background: $blue-light;

      .table-objective-details th {
        border-bottom: 1px solid $blue-mid;
      }
    }

    &[data-class-id="4"] {
      background: $green-light;

      .table-objective-details th {
        border-bottom: 1px solid $green-mid;
      }
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
  }

  .page-header__link-back {
    flex: 0 0 45px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .page-header__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    padding: 15px 15px 15px 0;
  }

  .page-header__breadcrumbs {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
    margin-top: -8px;

    i {
      position: relative;
      bottom: -6px;
    }
  }

  .page-header__title {
    font-size: 15px;
    line-height: 1.4;
  }

  .table-container {
    overflow-y: auto;
    padding: 5px;
  }

  .table-objective-details {
    width: 100%;
    padding: 20px 10px 10px 10px;
    border-collapse: collapse;

    th {
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      padding: 30px 20px 5px 20px;

      &:nth-child(1) {
        width: 90%;
      }
    }

    td {
      font-size: 14px;
      line-height: 1.4;
      vertical-align: top;
      padding: 3px 20px;

      &:nth-child(1) {
        padding-right: 0;
      }

    }

    tr:nth-child(1) td {
      padding-top: 10px;
    }

    &.activities {
      width:100%;

      th:nth-child(1) {
        // width: 80%;
      }
    }

  }

</style>
